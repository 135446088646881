import React from "react";

import "./Footer.css";

const Footer = () => {
  return (
    // <div className="footer">
    //   <img src="/assets/logo.gif" />
    // </div>
    null
  );
};

export default Footer;
