import { useEffect, useState } from "react";
import { Web3Auth } from "@web3auth/modal";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { CHAIN_NAMESPACES, SafeEventEmitterProvider } from "@web3auth/base";
import { OpenloginAdapter } from "@web3auth/openlogin-adapter";
import { CrossmintPayButton } from "@crossmint/client-sdk-react-ui";
import "./Login.css";
import { Presets, Client } from "userop";
import { auth, googleProvider,db } from "../config/firebase";
import Web3 from "web3";
import google from "./google-siginin.png"
import metamask from "./metamask.png"
import {
  createUserWithEmailAndPassword,
  signInWithPopup,
  signOut,
  signInWithEmailAndPassword
} from "firebase/auth";
import { ethers } from "ethers";
import {
  collection,
  doc,
  setDoc,
  getDocs,
  getFirestore,
  addDoc,
  orderBy,
  query,
  where,
  Timestamp,
} from "firebase/firestore";

import { useNavigate } from "react-router-dom";

function Login() {
  const [emails, setEmail] = useState("");
  const [password, setPassword] = useState("");

  





  const signUP = async () => {
    try {
     
       // Sign in with Google
       const authResult =  await createUserWithEmailAndPassword(auth, emails, password);
  
       const { email, displayName, photoURL } = authResult.user;
   
       // Check if the user's email already exists in Firestore
       const userQuery = query(collection(db, "users"), where("email", "==", email));
       const querySnapshot = await getDocs(userQuery);
   
       if (querySnapshot.size === 0) {
     
         const wallet = ethers.Wallet.createRandom();
   console.log(wallet)
         // Get the generated wallet address and private key
     
   
         // Store user data in Firestore
         const userData = {
           name: displayName,
           email: email,
           photoURL: photoURL,
           walletAddress: wallet.address,
           privateKey: wallet.privateKey,
        
         };
   
         const userRef = doc(db, "users", wallet.publicKey);
   
         // Set the user data in Firestore
         await setDoc(userRef, userData);
   
         console.log("User profile created and data stored in Firestore.",userData);
         const jsonString = JSON.stringify(userData);
         localStorage.setItem("userData", jsonString);
         window.location.href = "/inventory/token"
       } else {
       console.log('USer already exist')
        }
    }catch (error) {
      console.log(error)
      alert('Something Went Wrong')
  };
}
  const signInWithGoogle = async () => {
    try {
      // Sign in with Google
      const authResult = await signInWithPopup(auth, googleProvider);
  
      const { email, displayName, photoURL } = authResult.user;
  
      // Check if the user's email already exists in Firestore
      const userQuery = query(collection(db, "users"), where("email", "==", email));
      const querySnapshot = await getDocs(userQuery);
  
      if (querySnapshot.size === 0) {
        // User email doesn't exist in Firestore, proceed to create and store user data
  
        // Create a new wallet using ethers.js
        const wallet = ethers.Wallet.createRandom();
        console.log(wallet)
        // Get the generated wallet address and private key
        const publicKey = wallet.address;
        const privateKey = wallet.privateKey;
  
        // Store user data in Firestore
        const userData = {
          name: displayName,
          email: email,
          photoURL: photoURL,
          walletAddress: publicKey,
          privateKey: privateKey,
         
        };
  
        const userRef = doc(db, "users", publicKey);
  
        // Set the user data in Firestore
        await setDoc(userRef, userData);
  
        console.log("User profile created and data stored in Firestore.");
        const jsonString = JSON.stringify(userData);
        localStorage.setItem("userData", jsonString);
        window.location.href = "/inventory/token"
      } else {
        // User email already exists in Firestore, you can handle this case accordingly
        querySnapshot.forEach((doc) => {
          const userData = doc.data();
          console.log("User data from Firestore:", userData);
          const jsonString = JSON.stringify(userData);
          localStorage.setItem("userData", jsonString);
          window.location.href = "/inventory/token"
          // You can access user data here and perform any necessary actions
        });
      }
    } catch (err) {
      alert('Something Went Wrong')
      console.error(err);
    }
  };
  
  const initMetaMask = async () => {
    try {
      // Check if MetaMask is installed
      if ((window as any).ethereum) { // Use type assertion here
        const web3 = new Web3((window as any).ethereum);

        // Request access to the user's MetaMask account
        const accounts = await (window as any).ethereum.enable();
        const address = accounts[0];
        const userData = {
          name: null,
          email: null,
          photoURL: null,
          walletAddress: address,
          privateKey: null,
         
        };
        const jsonString = JSON.stringify(userData);
        localStorage.setItem("userData", jsonString);
        window.location.href = "/inventory/token"
        // Set the wallet address
        

        // Check if the Polygon network is available in MetaMask
        if ((window as any).ethereum.isPolygon) { // Use type assertion here
          // Replace with the correct Polygon (Matic) RPC URL
          const polygonRpcUrl = 'https://polygon-mumbai.infura.io/v3/4458cf4d1689497b9a38b1d6bbf05e78';

          // Switch to the Polygon network
          await (window as any).ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: '0x13881', // Chain ID for the Polygon Mainnet
                chainName: 'Polygon Mainnet',
                rpcUrls: [polygonRpcUrl],
                nativeCurrency: {
                  name: 'MATIC',
                  symbol: 'MATIC',
                  decimals: 18,
                },
                blockExplorerUrls: ['https://explorer.matic.network/'],
              },
            ],
          });

          console.log('Switched to Polygon Mainnet');

          // Get the wallet balance

        
        } else {
          console.error('Polygon network not available in MetaMask');
        }
      } else {
        console.error('MetaMask is not installed');
      }
    } catch (error) {
      alert('Something Went Wrong')
      console.error('Error:', error);
    }
  };



  const signIn = async () => {
    try {
      // Sign in with email and password
      const authResult = await signInWithEmailAndPassword(auth, emails, password);
  
      // Destructure user data
      const { email, displayName, photoURL } = authResult.user;
  
      // Check if the user's email already exists in Firestore
      const userQuery = query(collection(db, "users"), where("email", "==", email));
      const querySnapshot = await getDocs(userQuery);
  
      if (querySnapshot.size === 0) {
        // User doesn't exist in Firestore, you can handle this case accordingly
        console.log("User does not exist in Firestore.");
      } else {
        // User with the same email already exists in Firestore
        querySnapshot.forEach((doc) => {
          const userData = doc.data();
          console.log("User data from Firestore:", userData);
          const jsonString = JSON.stringify(userData);
          localStorage.setItem("userData", jsonString);
          window.location.href = "/inventory/token"
          // You can access user data here and perform any necessary actions
        });
      }
    } catch (error) {
      console.log(error);
      alert('Something Went Wrong')
      // Handle authentication errors here, such as incorrect password, user not found, etc.
    }
  };
  


  const logOut = async () => {
    try {
      await signOut(auth);
    } catch (err) {
      console.error(err);
    }
  };







  return (
    <div className="container">
      <div className="register">
        <div className="register__top">
          <div>
            <img
              src="/assets/logo+text.png"
              alt="Logo"
              className="register__logoGif"
            />
          </div>
          <div>
          <button type="button" className="google-signin-button " onClick={signInWithGoogle}>
      <img src={google} alt="Google Icon" />
      Sign in with Google
    </button>

    <button type="button" className="google-signin-button " style={{marginTop:'5%',marginBottom:'5%'}} onClick={initMetaMask}>
      <img src={metamask} alt="Google Icon" />
      Connect Metamask
    </button>
    <label htmlFor="email" style={{color:'white'}}>SignUp/SignIn with Email/Password</label>
      <div className="input-div">
          
          <label  htmlFor="email">Email:</label>
            <input
              id="to"
              type="text"
              placeholder="Email.."
              style={{textAlign:'left'}}
              
              onChange={(e) => setEmail(e.target.value)}
            />
            </div>
            
            <div className="input-div">
            <label htmlFor="email">Password:</label>
            <input
              type="text"
              placeholder="Password"
              
              style={{textAlign:'left'}}
              onChange={(e) => setPassword(e.target.value)}
            />
</div>
<label htmlFor="email" style={{color:'white',marginBottom:'5%'}}>Forget Password</label>
<div className="btn-div" style={{marginTop:'5%'}}>
        <button className="aqua-btn" onClick={signIn}>Signin</button>
        <button className="aqua-btn" onClick={signUP}>SignUp</button>
        </div>
        
            {/* <button className="card" onClick={signIn}> Signin</button>
            <button className="card" onClick={signUP}> SignUp</button>
            <label htmlFor="email" style={{color:'white'}}>Signup with Social</label>
            <button type="button" className="card" onClick={signInWithGoogle}> Signin with google</button>
            <button type="button" className="card" onClick={initMetaMask}> Connect with Metamask</button> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
