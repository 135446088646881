import React, { useEffect, useState } from "react";
import { CrossmintNFTCollectionView } from "@crossmint/client-sdk-react-ui";
import axios from "axios";
import TransferModal from "./transferModel";
import "react-toastify/dist/ReactToastify.css";
import { toast,ToastContainer } from "react-toastify";
const NFTOWN: React.FC = () => {
  const [nfts, setNFTs] = useState<any[]>([]);
  const [tokenAddress, setTokenAddress] = useState<any[]>([]);
  const [meta, setMeta] = useState<any[]>([]);
  const [data, setData] = useState<any[]>([]);
  const [id, setToken] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = (a:any) => {
    setIsModalOpen(true);
    setToken(a);
    console.log(a)
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
const setID = (idd:any) =>{
  setToken(idd)
}
  useEffect(() => {
    const ab = window.localStorage.getItem("userData") as string;
    
    const storedObject = JSON.parse(ab);
    // console.log(storedObject);
    setData(storedObject);
  }, []);

  useEffect(() => {
    getTokens();
  }, []);
  async function getTokens() {
    try {
      const ab = window.localStorage.getItem("userData") as string;
      console.log(ab);
      const storedObject = JSON.parse(ab);

      const apiUrl = `https://deep-index.moralis.io/api/v2/${storedObject.walletAddress}/nft?chain=mumbai`;
      const apiKey =
        "eYcwRjbNUaKHO1LjLAa0C77hVkQiokQFesWe7QkOhtQKfaqGEwwE3vbtZGtDuIIx"; // Replace with your actual API key

      // Define the headers
      const headers = {
        accept: "application/json",
        "X-API-Key": apiKey,
      };

      // Make the GET request using Axios
      axios
        .get(apiUrl, { headers })
        .then((response) => {
          console.log(response, "res");
          // Handle the API response here
          console.log("API Response:", response.data.result.metadata);
          setNFTs(response.data.result);
          console.log(response.data.length, "length");
          let images: any = [];
          setMeta(images);
          console.log(images, "images");
          for (let i = 0; i <= response.data.result.length; i++) {
            let a = JSON.parse(response.data.result[i].metadata);
            images.push(a);
          }
        })
        .catch((error) => {
          console.error("API Error:", error);
        });
    } catch (error) {}
  }

  const handleButtonClick = (jsonData: any) => {
    // Convert the JSON data to a string
    const jsonString = JSON.stringify(jsonData, null, 2);

    // Create a new Blob with the JSON data
    const blob = new Blob([jsonString], { type: "application/json" });

    // Create a URL for the Blob
    const url = URL.createObjectURL(blob);

    // Open a new tab with the URL
    window.open(url, "_blank");
  };

  // console.log(nfts, "nfts", meta, "meta");
  return (
    <div className="nft-container">
      {nfts.map((nft) => (
        <>
          <div key={nft.tokenId} className="nftCard">
            {/* {meta?.map((meta) => (
<>
<div>
<img
              src={meta?.image}
              alt={`NFT ${meta?.image              }`}
              className="nft-image"
             
            />
            </div>
</>

              ))} */}
            <div className="flexButton">
              <div>
                <p className="nft-name">Name: {nft.name} </p>
                <p className="nft-token-id">Price: ${nft.price}</p>
                <p className="nft-token-id">Price: ${nft.tokenId}</p>
              </div>

              <div></div>
              <div className="btn-div" style={{ marginTop: "5%" }}>
                <button
                  className="aqua-btn"
                  onClick={() => handleButtonClick(nft.metadata)}
                >
                  Check Metadata
                </button>
                <button className="aqua-btn" onClick={()=>{openModal(nft.token_id)}}>
                  Transfer
                </button>
              </div>
            </div>
          </div>
        </>
      ))}
      <TransferModal isOpen={isModalOpen} onClose={closeModal} data={data} tokenId={id}
     
      
      />
       <ToastContainer />
    </div>
  );
};

export default NFTOWN;
