import "./Tokens.css";
import NFTAll from "../AllNft/AllNft";
import { useEffect, useState } from "react";
import axios from "axios";

const Tokens = () => {
  const [nfts, setNFTs] = useState<any[]>([]); 
  const [tokenAddress,setTokenAddress] = useState<any[]>([]);
 

  useEffect(()=>{
    getTokens()
    },[])
    async function getTokens() {
      try {
        const ab = window.localStorage.getItem("userData") as string;
        console.log(ab);
        const storedObject = JSON.parse(ab);
    
        const apiUrl =
          `https://deep-index.moralis.io/api/v2/${storedObject.walletAddress}/erc20?chain=mumbai`;
        const apiKey = "eYcwRjbNUaKHO1LjLAa0C77hVkQiokQFesWe7QkOhtQKfaqGEwwE3vbtZGtDuIIx"; // Replace with your actual API key
    
        // Define the headers
        const headers = {
          accept: "application/json",
          "X-API-Key": apiKey,
        };
    
        // Make the GET request using Axios
        axios
          .get(apiUrl, { headers })
          .then((response) => {
            // Handle the API response here
            console.log("API Response:", response.data);
            setTokenAddress(response.data)
          })
          .catch((error) => {
            // Handle errors
            console.error("API Error:", error);
          });
      } catch (error) {}
    }
    


  return (
    <div className="token-page">
      <h1>
        Tokens
        {/* <span>Last updated a minute ago</span> */}
      </h1>
      <div className="bottom-token">
        <br />
        {tokenAddress.length === 0 ? (
          <h2>We haven't found any tokens in this wallet.</h2>
        ) : (
          <table style={{ color: 'white', borderCollapse: 'collapse', width: '100%' }}>
          <thead>
            <tr>
              <th style={{ backgroundColor: 'black', border: '1px solid white' }}>Name</th>
              <th style={{ backgroundColor: 'black', border: '1px solid white' }}>Symbol</th>
              <th style={{ backgroundColor: 'black', border: '1px solid white' }}>Balance</th>
              <th style={{ backgroundColor: 'black', border: '1px solid white' }}>Decimals</th>
              <th style={{ backgroundColor: 'black', border: '1px solid white' }}>Possible Spam</th>
              <th style={{ backgroundColor: 'black', border: '1px solid white' }}>Token Address</th>
            </tr>
          </thead>
          <tbody>
            {tokenAddress.map((token, index) => (
              <tr key={index}>
                <td style={{ border: '1px solid white' }}>{token.name}</td>
                <td style={{ border: '1px solid white' }}>{token.symbol}</td>
                <td style={{ border: '1px solid white' }}>{token.balance}</td>
                <td style={{ border: '1px solid white' }}>{token.decimals}</td>
                <td style={{ border: '1px solid white' }}>{token.possible_spam ? 'Yes' : 'No'}</td>
                <td style={{ border: '1px solid white' }}>{token.token_address}</td>
              </tr>
            ))}
          </tbody>
        </table>
        )}
      </div>
    </div>
  );
};

export default Tokens;
