import React, { useState } from "react";
import "./model.css";
import { transfer,TransferWithMetamask } from "./backend";

const TransferModal = ({ isOpen, onClose, data, tokenId }: any) => {
  const [inputValue, setInputValue] = useState("");
  const [id, setID] = useState(tokenId);
  console.log(tokenId)
  const handleInputChange = (e: any) => {
    setInputValue(e.target.value);
  };

  const handleSave = () => {
    onClose();
  };

  return (
    <div className={`modal ${isOpen ? "open" : ""}`}>
      <div className="modal-content">
        <span className="close" onClick={onClose}>
          &times;
        </span>
        <h2 style={{ color: "white" }}>Transfer</h2>
        <div className="input-div">
          <label htmlFor="to">To Wallet</label>
          <input
            type="text"
            id="to"
            value={inputValue}
            onChange={handleInputChange}
          />
        </div>
        <div className="btn-div" style={{ marginTop: "5%" }}>
            {data.privateKey != null ?
          <button
            className="aqua-btn"
            onClick={() =>
              transfer(data.privateKey, inputValue, tokenId, data.walletAddress)
            }
          >
            Transfer
          </button>
          :
          <button
          className="aqua-btn"
          onClick={() =>
            TransferWithMetamask( inputValue,  data.walletAddress,tokenId)
          }
        >
          Transfer
        </button>
}
        </div>
      </div>
    </div>
  );
};

export default TransferModal;
