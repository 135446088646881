import { ethers } from "ethers";
import abi from "./abi.json";
import { contractAddress, providerss } from "./con1";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
export async function transfer(privateKey: any, to: any, tokenId: any, address: any) {
  try {
    toast("Processing...");
    console.log(privateKey, address, address, tokenId);
    const node = providerss;
    const provider = new ethers.providers.JsonRpcProvider(node);
    const wallet = new ethers.Wallet(privateKey, provider);
    const contract = new ethers.Contract(contractAddress, abi, wallet);
    console.log(contract, wallet);
    const gasPrice = await provider.getGasPrice();
    const gasPriceGwei = ethers.utils.formatUnits(gasPrice, "gwei");
    console.log(`Current Gas Price: ${gasPriceGwei} Gwei`);
    const tx = await contract.transferFrom(address, to, tokenId, {
      maxFeePerGas: Math.floor(Number(gasPriceGwei)) + 2,
      maxPriorityFeePerGas: Math.floor(Number(gasPriceGwei)) + 2,
      gasLimit: 285000,
    });
    console.log(tx.hash);
    toast(`Transaction is in pending Here is the hash. ${tx.hash}`);
    const receipt = await tx.wait();
    console.log(receipt.status);
  } catch (error: any) {
    console.log(error);
    let errorMessage =
      (error?.message && error.message) ||
      (error?.data && error.data.message && error.data.message) ||
      "Transaction Failed";
    errorMessage = errorMessage.slice(0, 28);
    toast.error(errorMessage);
  }
}

export async function TransferWithMetamask(publicKey: any, to: any, tokenId: any) {
  try {
    toast("Processing...");
    console.log(publicKey, to, tokenId);
    const node = providerss; // Assuming you have the correct RPC endpoint here

    // Check if MetaMask is available
    if ((window as any).ethereum) {
      try {
        // Request access to MetaMask accounts
        await (window as any).ethereum.enable();
        const provider = new ethers.providers.Web3Provider((window as any).ethereum);
        const signer = provider.getSigner();
        const accounts = await (window as any).ethereum.request({ method: "eth_requestAccounts" });
        const currentAccount = accounts[0];
        console.log(currentAccount);

        console.log(accounts);
        const contract = new ethers.Contract(contractAddress, abi, signer);
        console.log(contract);

        const gasPrice = await provider.getGasPrice();
        const gasPriceGwei = ethers.utils.formatUnits(gasPrice, "gwei");
        console.log(`Current Gas Price: ${gasPriceGwei} Gwei`);

        const tx = await contract.transferFrom(publicKey, to, tokenId, {
          maxFeePerGas: Math.floor(Number(gasPriceGwei)) + 2,
          maxPriorityFeePerGas: Math.floor(Number(gasPriceGwei)) + 2,
          gasLimit: 285000,
        });

        console.log(tx.hash);
        toast(`Transaction is in pending. Here is the hash: ${tx.hash}`);

        const receipt = await tx.wait();
        console.log(receipt.status);
      } catch (error: any) {
        console.error(error);
        toast(`Error processing transaction: ${error.message}`);
      }
    } else {
      toast("MetaMask not detected. Please install MetaMask to proceed.");
    }
  } catch (error) {}
}
