import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faWallet, faChevronLeft } from "@fortawesome/free-solid-svg-icons";

import { Link } from "react-router-dom";

export default function Blockchain({   }) {
  // setSelectedBlockChain, blockchain
  return (
    // <div
    //   className={`home__blockchainSelected ${
    //     blockchain ? "show-blockchain" : ""
    //   }`}
    // >
    //   <div>{blockchain}</div>

    //   <Link to="/inventory" className="home__createWallet">
    //     <FontAwesomeIcon icon={faWallet} /> Create New Wallet
    //   </Link>

    //   <button className="home__importWallet">
    //     {" "}
    //     <FontAwesomeIcon icon={faWallet} />
    //     Import Wallet
    //   </button>

    //   <button className="home__back" onClick={() => setSelectedBlockChain("")}>
    //     <FontAwesomeIcon icon={faChevronLeft} /> Back
    //   </button>
    // </div>
    null
  );
}
