import React, { useEffect, useState } from "react";
import "./Transaction.css";
import { ERC20_ABI } from "./abi.js";
import { ethers } from "ethers";
import { Presets, Client } from "userop";
import axios from "axios";

const Transaction = () => {
  const [amounts, setAmount] = useState("");
  const [tokens, setToken] = useState("");
  const [address, setAddress] = useState("");
  const [tokenAddress,setTokenAddress] = useState<any[]>([]);
useEffect(()=>{
  getTokens()
},[])




  async function transfer() {
    try {
      console.log(tokens,amounts)
      const ab = window.localStorage.getItem("userData") as string;
      console.log(ab);
      const storedObject = JSON.parse(ab);

      const signer = new ethers.Wallet(storedObject.privateKey);
      const rpcUrl =
        "https://api.stackup.sh/v1/node/d6f7e2a26cf6d85987358ec2355d7b78cc8e5074aa0fd1fd7f9c85d13aec2cd0";
      const paymasterRpcUrl =
        "https://api.stackup.sh/v1/paymaster/d6f7e2a26cf6d85987358ec2355d7b78cc8e5074aa0fd1fd7f9c85d13aec2cd0";
      const paymasterContext = { type: "payg" };
      const paymaster = Presets.Middleware.verifyingPaymaster(
        paymasterRpcUrl,
        paymasterContext
      );

      const builder = await Presets.Builder.Kernel.init(signer, rpcUrl, {
        paymasterMiddleware: paymaster,
      });
      const address = builder.getSender();

      const too = address;
      const token = tokens;
      const value = amounts;

      const provider = new ethers.providers.JsonRpcProvider(rpcUrl);
      const erc20 = new ethers.Contract(token, ERC20_ABI, provider);
      const decimals = await Promise.all([erc20.decimals()]);
      const amount = ethers.utils.parseUnits(value, decimals);
      console.log(+amount);

      // Create the calls
      const approve = {
        to: token,
        value: ethers.constants.Zero,
        data: erc20.interface.encodeFunctionData("approve", [too, amount]),
      };

      console.log(token, too, amount);

      const send = {
        to: token,
        value: ethers.constants.Zero,
        data: erc20.interface.encodeFunctionData("transfer(address,uint256)", [
          too,
          amount,
        ]),
      };

      const calls = [send];

      const client = await Client.init(rpcUrl);
      const res = await client.sendUserOperation(builder.execute(send), {
        onBuild: (op) => console.log("Signed UserOperation:", op),
      });

      console.log(`UserOpHash: ${res.userOpHash}`);
      console.log("Waiting for transaction...");
      const ev = await res.wait();
      console.log(`Transaction hash: ${ev?.transactionHash ?? null}`);
      alert(`Transaction Sucess hash: ${ev?.transactionHash ?? null}`);
    } catch (error) {
      console.log(error);
      alert("Something Went Wrong");
    }
  }

  async function getTokens() {
    try {
      const ab = window.localStorage.getItem("userData") as string;
      console.log(ab);
      const storedObject = JSON.parse(ab);

      const apiUrl =
        `https://deep-index.moralis.io/api/v2/${storedObject.walletAddress}/erc20?chain=mumbai`;
      const apiKey = "eYcwRjbNUaKHO1LjLAa0C77hVkQiokQFesWe7QkOhtQKfaqGEwwE3vbtZGtDuIIx"; // Replace with your actual API key

      // Define the headers
      const headers = {
        accept: "application/json",
        "X-API-Key": apiKey,
      };

      // Make the GET request using Axios
      axios
        .get(apiUrl, { headers })
        .then((response) => {
          // Handle the API response here
          console.log("API Response:", response.data);
          setTokenAddress(response.data)
        })
        .catch((error) => {
          // Handle errors
          console.error("API Error:", error);
        });
    } catch (error) {}
  }

  return (
    <div className="transaction-page">
      <h1>Send Funds</h1>
      <div className="input-div">
        <label htmlFor="to">Token Addresses You Hold</label>
     
        <select
      style={{
        width: '100%',
        height: '40px',
        borderRadius: '2px',
        fontSize: '16px',
        border: '1px solid #4ca2af',
        paddingLeft: '10px',
        backgroundColor: 'transparent',
        color: 'white',
      }}
        onChange={(event) => setToken(event.target.value)}>
        {tokenAddress?.map((token:any, index:any) => (
          <option key={index} value={token.token_address}>
            {token.name} ({token.symbol})
          </option>
        ))}
        </select>
      </div>
      <div className="input-div">
        <label htmlFor="to">
          To Wallet / Address / E-mail / Unstoppable domain
        </label>
        <input
          type="text"
          id="to"
          value={address} // Bind the input value to the state variable
          onChange={(event) => setAddress(event.target.value)} // Use onChange directly
        />
      </div>
      <div className="input-div">
        <label htmlFor="amount">
          Amount (Available Balance after transaction 0.00000 ETH)
        </label>
        <div>
          <input
            type="text"
            id="amount"
            value={amounts} // Bind the input value to the state variable
            onChange={(event) => setAmount(event.target.value)} // Use onChange directly
          />
          <button>ETH</button>
        </div>
      </div>
      <button className="aqua-btn" onClick={transfer}>
        Send Funds
      </button>

      <p className="note">
        After clicking on 'Send Funds', a pop-up will open where you will be
        asked to confirm the transaction using your pin code.
      </p>
    </div>
  );
};

export default Transaction;
